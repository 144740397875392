<script setup>
import { useTabs } from "./useTabs";

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const tabs = useTabs();
const tabsGroup = reactive(tabs);
const controlEl = ref(null);
const sliderEl = ref(null);
const cssSliderWidth = ref(0);
const cssSliderX = ref(0);

const ob = new MutationObserver(() => updateSliderPosition());

provide("tabsGroup", tabsGroup);

watchEffect(() => setValue(props.modelValue));

function updateSliderPosition() {
  if (!controlEl.value) return;

  const tabEl = getActiveTabHtmlElem();
  if (!tabEl) return;

  cssSliderWidth.value = toPixels(tabEl.clientWidth);
  cssSliderX.value = toPixels(tabEl.offsetLeft);
}

function assignObservers() {
  const tabs = controlEl.value.querySelectorAll(`.v-tab-label`);
  ob.disconnect();
  Array.from(tabs).map((el) => {
    ob.observe(el, { childList: true, subtree: true });
  });
}

function getActiveTabHtmlElem() {
  const tabs = controlEl.value.querySelectorAll(`.v-tab-label`);
  const activeTabIndex = tabsGroup.tabsColl.findIndex((el) => el.value.isTabActive);
  if (activeTabIndex < 0) return;

  const tabEl = tabs[activeTabIndex];

  return tabEl;
}

function setValue(value) {
  tabsGroup.activate(value);
  updateSliderPosition();
}

function updateValue() {
  const tabEl = tabsGroup.tabsColl.find((t) => t.value.isTabActive);
  if (tabEl?.value) {
    emit("update:modelValue", tabEl.value.tabValue);
  }
}

tabs.onChange(() => {
  assignObservers();
  updateValue();
});

onMounted(() => {
  assignObservers();
});
</script>
<template>
  <div class="v-tabs-labels-el" :ref="(el) => (controlEl = el)">
    <div class="tabs">
      <slot></slot>
      <span class="slider" :ref="(el) => (sliderEl = el)"></span>
    </div>
  </div>
</template>
<style scoped>
.tabs {
  display: flex;
  position: relative;
  background-color: var(--background, #fff);
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  /* border-radius: 99px; */
}
.tabs * {
  z-index: 2;
}

.slider {
  position: absolute;
  display: flex;
  height: 3px;
  bottom: -4px;
  width: v-bind(cssSliderWidth);
  background-color: var(--root-green);
  /* var(--basis-color); */

  z-index: 1;
  border-radius: 99px;
  transition: 0.2s ease-out;
  transform: translate(v-bind(cssSliderX));
}
</style>
